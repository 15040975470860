import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  refineIncludeCriterion,
  refineExcludeCriterion,
  refineRemoveCriterion,
  refineReplaceCriterion,
} from 'generic/core/search/actions';
import Criteria from 'generic/components/ui/Criteria';

const CriteriaContainer = () => {
  const criteria = useSelector((state) => state.search.results.criteres);
  const dispatch = useDispatch();

  const handleExclude = (criterion) => {
    dispatch(refineExcludeCriterion(criterion));
  };

  const handleInclude = (criterion) => {
    dispatch(refineIncludeCriterion(criterion));
  };

  const handleRemove = (criterion) => {
    dispatch(refineRemoveCriterion(criterion));
  };

  const handleEdit = (criterion, value) => {
    dispatch(refineReplaceCriterion(criterion, value));
  };

  if (_.isEmpty(criteria)) {
    return null;
  }

  return (
    <Criteria
      criteria={criteria}
      handleExclude={handleExclude}
      handleInclude={handleInclude}
      handleRemove={handleRemove}
      handleEdit={handleEdit}
    />
  );
};

export default CriteriaContainer;

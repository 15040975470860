import queryString from 'query-string';
import QES_CONSTANTS from 'generic/core/qes/constants';

import {
  getTokenFromStorage,
  getLogonFromStorage,
} from 'generic/utils/utils';

const { API_ENTRY_POINT } = QES_CONSTANTS;

const genericGenerator = (basePath, method) => ({
  path, // chemin depuis le basepath vers le endpoint concerné
  uriParams, // un objet qui sera décomposé en des paramètres dans l'url
  rawUriParam, // paramètre à passer dans l'url tel quel
  bodyItems, // le corps de la requête, peut contenir le "fichier" s'il s'agit d'un fileupload ou d'un object json
  withToken = true, // permet de ne pas envoyer le token d'authentification, utile pour le login
  fileUpload = false, // permet de savoir si on fait un upload de fichier ou non
}) => {
  let paramsStr;
  let body;

  if (uriParams) {
    paramsStr = queryString.stringify(uriParams);
  }

  const headers = {};
  if (fileUpload) {
    body = bodyItems;
  } else {
    headers['Content-Type'] = 'application/json';
    body = bodyItems ? JSON.stringify(bodyItems) : undefined;
  }

  if (withToken) {
    headers.logon = getLogonFromStorage();
    headers.key = getTokenFromStorage();
  }

  let url = basePath ? `${basePath}${path}` : path;
  if (paramsStr) {
    url += `?${paramsStr}`;
  }

  if (rawUriParam) {
    url += (paramsStr) ? `&${rawUriParam}` : `?${rawUriParam}`;
  }

  return fetch(`${url}`, {
    method,
    headers,
    body,
    credentials: 'include',
  });
};

const del = genericGenerator(API_ENTRY_POINT, 'DELETE');
const get = genericGenerator(API_ENTRY_POINT, 'GET');
const patch = genericGenerator(API_ENTRY_POINT, 'PATCH');
const post = genericGenerator(API_ENTRY_POINT, 'POST');

export {
  del,
  get,
  patch,
  post,
};

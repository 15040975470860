import React from 'react';
import reactDom from 'react-dom';
import PropTypes from 'prop-types';

import { Dialog, DialogContent } from '@mui/material';

import GEDContainer from 'generic/containers/GEDContainer';
import { useDispatch, useSelector } from 'react-redux';
import { fastGedClose } from 'generic/core/ged/actions';

const GEDModalContainer = ({ afterSave }) => {
  const dispatch = useDispatch();
  const { id, base } = useSelector((state) => state.ged.fastGed);
  const isVisible = !!id;

  return (
    isVisible
    && reactDom.createPortal(<Dialog
      fullWidth
      maxWidth="lg"
      open={isVisible}
    >
      <DialogContent sx={{
        maxHeight: '80vh', wrap: 'nowrap', display: 'flex', p: 0,
      }}
      >
        <GEDContainer
          id={id}
          base={base}
          afterSave={(...rest) => {
            dispatch(fastGedClose());
            afterSave(...rest);
          }}
          variant="light"
          onCloseDialog={() => dispatch(fastGedClose())}
          sx={{
            '.GEDContent': {
              px: 1,
            },
          }}
        />
      </DialogContent>
      {/* eslint-disable-next-line react/jsx-closing-tag-location */}
    </Dialog>, document.body)
  );
};

GEDModalContainer.propTypes = {
  afterSave: PropTypes.func.isRequired,
};

export default GEDModalContainer;

import _ from 'lodash';
import { types } from 'generic/core/ged/actions';

const gedReducer = (
  state = {
    document: {},
    files: { uploading: false },
    loading: false,
    multiple: false,
    fastGed: {},
  },
  action,
) => {
  switch (action.type) {
    case types.CLEANUP_DOCUMENT: {
      return {
        ...state,
        document: {},
        files: { uploading: false },
      };
    }
    case types.FETCH_DOCUMENT: {
      return {
        ...state,
        loading: true,
        document: {},
      };
    }
    case types.FETCH_DOCUMENT_SUCCESS: {
      // On va préparer les champs multiples
      const results = { ...action.results };
      // On commence par déclarer deux Set pour stocker le nom
      // de tous les champs, et les préfixes de tous les champs
      // (un préfixe est par exemple "PDF", pour un champ avec
      // le name="PDF_1", le reste est fait plus loin)
      const allFieldsNames = new Set();
      const multipleFieldsPrefixes = new Set();
      for (let i = 0; i < results.champs.length; i++) {
        const fieldName = results.champs[i].name;
        // On met tous les champs en isVisible (seront affichés
        // dans le formulaire de GED)
        results.champs[i].isVisible = true;
        // On ajoute dans allFieldsNames le nom de chaque champ
        allFieldsNames.add(fieldName);

        // Ensuite pour chaque champ on va tenter de découper leur
        // name, en fonction de la présence d'un _ suivi d'une fin
        // de chaîne correspondant à un entier
        const r = /^(.*)_(\d+)$/;
        const result = r.exec(fieldName);
        let prefix = fieldName;
        let index = 0;
        if (result) {
          // Si l'execution de la regex renvoie quelque chose, c'est
          // qu'on a bien un nom du type "Truc_chose_XX", exemples :
          // PDF_3, Mot_Clef_2, date_publication_1...
          // On récupère donc et on met à jour les éléments prefix
          // et index
          const [, resultPrefix, resultIndex] = result;
          prefix = resultPrefix;
          index = +resultIndex;
        }
        // On balance les deux valeurs sur le champ
        results.champs[i].prefix = prefix;
        results.champs[i].index = index;
        // Ensuite, on met de côté le "préfixe" de tous les champs
        // multiples
        if (results.champs[i].isMultiple) {
          multipleFieldsPrefixes.add(prefix);
        }
      }
      // On boucle une seconde fois sur tous les champs
      for (let i = 0; i < results.champs.length; i++) {
        // Si jamais le prefix du champ fait partie des prefixes
        // des champs multiples
        if (multipleFieldsPrefixes.has(results.champs[i].prefix)) {
          // On construit le nom du champ suivant en incrémentant son index
          const nextFieldName = `${results.champs[i].prefix}_${results.champs[i].index + 1}`;
          if (allFieldsNames.has(nextFieldName)) {
            // Si jamais le nom du champ suivant existe bien dans les champs
            // disponibles, on ajoute au champ le nextFieldName
            results.champs[i].nextFieldName = nextFieldName;
          }
          if (results.champs[i].index > 0
            && _.isEmpty(results.champs[i].value)) {
            // De plus, si jamais le champ dispose d'un index et que sa valeur
            // est vide, c'est qu'à priori il faut le masquer dans le formulaire
            results.champs[i].isVisible = false;
          }
        }
      }
      return {
        ...state,
        loading: false,
        document: results,
      };
    }
    case types.FETCH_DOCUMENT_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case types.UNSET_DOCUMENT: {
      return {
        ...state,
        document: {},
      };
    }
    case types.TOGGLE_NEW_MULTIPLE_FIELD: {
      const champs = [...state.document.champs];
      // On récupère le champ "courant" dans le state (celui depuis
      // lequel on a demandé l'ajout d'un champ supplémentaire en
      // cliquant sur le bouton "+" associé)
      const currentField = _.find(champs, { name: action.fieldName });
      // On boucle sur les champs
      for (let i = 0; i < champs.length; i++) {
        if (champs[i].name === action.fieldName) {
          // On passe le isMultiple du champ courant à false (permet
          // de virer son bouton "+")
          champs[i].isMultiple = false;
        } else if (!_.isEmpty(currentField.nextFieldName) && champs[i].name === currentField.nextFieldName) {
          // Si le champ "suivant" existe, on passe son isVisible à true
          // et son isMultiple à true
          champs[i].isVisible = true;
          champs[i].isMultiple = true;
        }
      }
      return {
        ...state,
        document: {
          ...state.document,
          champs,
        },
      };
    }
    case types.UPLOAD_GED_FILE: {
      return {
        ...state,
        files: {
          ...state.files,
          uploading: true,
        },
      };
    }
    case types.UPLOAD_GED_FILE_SUCCESS: {
      return {
        ...state,
        files: {
          ...state.files,
          [action.fieldName]: action.results.filename,
          uploading: false,
        },
      };
    }
    case types.UPLOAD_GED_FILE_ERROR: {
      return {
        ...state,
        files: {
          ...state.files,
          uploading: false,
        },
      };
    }
    case types.CLEAR_UPLOAD_GED_FILE: {
      return {
        ...state,
        files: {
          ...state.files,
          [action.fieldName]: '',
        },
      };
    }
    case types.SET_MULTIPLE: {
      return {
        ...state,
        multiple: action.multiple,
      };
    }
    case types.FAST_GED_OPEN: {
      return {
        ...state,
        fastGed: {
          id: action.id,
          base: action.base,
        },
      };
    }
    case types.FAST_GED_CLOSE: {
      return {
        ...state,
        fastGed: {},
      };
    }
    case types.SAVE_DOCUMENT: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.SAVE_DOCUMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case types.SAVE_DOCUMENT_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default gedReducer;

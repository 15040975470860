import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { closeDialogUploadFilesToCreateDocuments } from 'generic/core/actions/actions';
import UploadFilesToCreateDocumentsDialog from 'generic/components/dialogs/UploadFilesToCreateDocumentsDialog';

const UploadFilesToCreateDocumentsDialogContainer = ({
  baseId,
  baseMisePageId,
}) => {
  const open = useSelector((state) => state.actions.dialogUploadFilesToCreateDocumentsOpened);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeDialogUploadFilesToCreateDocuments());
  };

  if (!open) {
    return null;
  }
  return (
    <UploadFilesToCreateDocumentsDialog
      handleClose={handleClose}
      baseId={baseId}
      baseMisePageId={baseMisePageId}
    />
  );
};

UploadFilesToCreateDocumentsDialogContainer.propTypes = {
  baseId: PropTypes.number.isRequired,
  baseMisePageId: PropTypes.number.isRequired,
};

export default UploadFilesToCreateDocumentsDialogContainer;

export const percentage = (partialValue, totalValue) => {
  if (Number.isNaN(partialValue) || Number.isNaN(totalValue)) return 0;
  if (partialValue === 0 && totalValue === 0) return 0;

  return (partialValue / totalValue) * 100;
};

export const prettyPrintNumber = (number, withSuffixes) => {
  if (Number.isNaN(number)) {
    return number;
  }
  if (withSuffixes !== true) {
    return parseInt(number, 10).toLocaleString();
  }
  // Après on utilise un bout de code pompé sur le net
  // qui marche pas mal, pour formatter les grands nombres
  const SI_SYMBOL = ['', 'K', 'M', 'G', 'T', 'P', 'E'];
  // what tier? (determines SI symbol)
  // eslint-disable-next-line no-bitwise
  const tier = Math.log10(Math.abs(number)) / 3 | 0;

  // if zero, we don't need a suffix
  if (tier === 0) return number;

  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier];
  const scale = 10 ** (tier * 3);

  // scale the number
  const scaled = number / scale;
  // Si jamais le tier est au minimum "M", on affiche 1 chiffre après
  // la virgule
  if (tier > 1) {
    const value = scaled.toFixed(1) + suffix;
    if (window.QWAM.localeId === 1) {
      value.replace(/\./g, ',');
    }
    return value;
  }
  // format number and add suffix
  return scaled.toFixed(0) + suffix;
};

import {
  format as dateFnsFormat,
  formatDistance as dateFnsFormatDistance,
} from 'date-fns';
import { enGB, fr } from 'date-fns/locale';

const locales = {
  1: fr,
  2: enGB,
};

/**
 * Récupére la liste des jours de la semaine dans la locale "courante" de l'app
 * @param {string} width permet de préciser le format dans lequel on veut récupérer les noms des jours de la semaine :
 *   - `narrow` > Seulement les initialies (_D_, _L_, _M_, ...)
 *   - `abbreviated` > Le format abrégé (_dim._, _lun._, _mar._, ...)
 *   - `wide` > Le format complet (_dimanche_, _lundi_, _mardi_, ...), format par défaut
 */
export const getWeekdays = (width) => {
  let finalWitdh = width;
  if (!finalWitdh) {
    finalWitdh = 'wide';
  }
  const locale = locales[window.QWAM.localeId];
  return [...Array(7).keys()].map((i) => locale.localize.day(i, { width: finalWitdh }));
};

/**
 * Récupère la liste des mois de l'année dans la locale "courante" de l'app
 * @param {string} width permet de préciser le format dans lequel on veut récupérer les noms des mois
 *   - `narrow` > Seulement les initialies (_J_, _F_, _M_, ...)
 *   - `abbreviated` > Le format abrégé (_jan._, _fév._, _mar._, ...)
 *   - `wide` > Le format complet (_janvier_, _février_, _mars_, ...), format par défaut
 */
export const getMonths = (width) => {
  let finalWitdh = width;
  if (!finalWitdh) {
    finalWitdh = 'wide';
  }
  const locale = locales[window.QWAM.localeId];
  return [...Array(12).keys()].map((i) => locale.localize.month(i, { width: finalWitdh }));
};

/**
 * Formate une date avec la localisation courante de l'app
 * @param {number|string|Date} date La Date à formatter
 * @param {string} formatStr le format est le même que sur la méthode format de date-fns
 *   à l'exeption du format custom `full` qui permet de retourner en fonction de la langue :
 *   - FR: _lundi 15 janvier 2022_
 *   - EN: _Monday, 15 january 2022_
 *
 *   De plus, l'année n'est pas affichée s'il s'agit de l'année courante
 * @param {object} options les options sont le mêmes que sur la méthode format de `date-fns`
 * @see module:date-fns/format
 */
export const format = (date, formatStr = 'P', options = {}) => {
  const finalDate = new Date(date);
  let finalFormatStr = formatStr;
  if (formatStr === 'full') {
    if (window.QWAM.localeId === 1) {
      finalFormatStr = 'EEEE d MMMM';
    } else if (window.QWAM.localeId === 2) {
      finalFormatStr = 'EEEE, d MMMM';
    }

    if (finalDate.getFullYear() !== new Date().getFullYear()) {
      finalFormatStr += ' yyyy';
    }
  }

  return dateFnsFormat(finalDate, finalFormatStr, {
    ...options,
    locale: locales[window.QWAM.localeId],
  });
};

/**
 * Formate une distance avec la localisation courante de l'app
 * @param {number|Date} date la date de fin
 * @param {number|Date} baseDate la date de départ
 * @param {object} options les options sont le mêmes que sur la méthode `formatDistance` de `date-fns`
 * @returns {string} _il y a 3 secondes_, _il y a 10 minutes_, etc...
 * @see module:date-fns/formatDistance
 */
export const formatDistance = (date, baseDate, options = {}) => (
  dateFnsFormatDistance(date, baseDate, {
    ...options,
    locale: locales[window.QWAM.localeId],
  })
);

import _ from 'lodash';
import { types } from 'generic/core/files/actions';

const filesReducer = (
  state = {
    filesToUpload: {},
  },
  action,
) => {
  switch (action.type) {
    case types.UPLOAD_FILE_TO_CREATE_DOCUMENT: {
      const filesToUploadCloned = _.cloneDeep(state.filesToUpload);
      filesToUploadCloned[action.name] = { loading: true };
      return {
        ...state,
        filesToUpload: filesToUploadCloned,
      };
    }
    case types.UPLOAD_FILE_TO_CREATE_DOCUMENT_SUCCESS: {
      const filesToUploadCloned = _.cloneDeep(state.filesToUpload);
      const { fileName } = action.results;
      if (filesToUploadCloned[fileName]) {
        filesToUploadCloned[fileName] = { loading: false, success: true };
      }
      return {
        ...state,
        filesToUpload: filesToUploadCloned,
      };
    }
    case types.UPLOAD_FILE_TO_CREATE_DOCUMENT_ERROR: {
      const filesToUploadCloned = _.cloneDeep(state.filesToUpload);
      const { fileName } = action.results;
      if (filesToUploadCloned[fileName]) {
        filesToUploadCloned[fileName] = { loading: false, success: false };
      }
      return {
        ...state,
        filesToUpload: filesToUploadCloned,
      };
    }
    case types.CLEAN_FILES_TO_UPLOAD: {
      return {
        ...state,
        filesToUpload: {},
      };
    }
    default:
      return state;
  }
};

export default filesReducer;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import UploadFilesToCreateDocuments from 'generic/components/pages/UploadFilesToCreateDocuments';
import QES_CONSTANTS from 'generic/core/qes/constants';
import { cleanFilesToUpload, uploadFileToCreateDocument } from 'generic/core/files/actions';

const {
  FILE_UPLOAD_FORMATS,
} = QES_CONSTANTS;

const UploadFilesToCreateDocumentsContainer = ({
  baseId,
  baseMisePageId,
}) => {
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState({});
  const filesToUpload = useSelector((state) => state.files.filesToUpload);

  const uploadParams = {
    base: baseId,
    base_mise_page: baseMisePageId,
    create: true,
  };

  // Cleanup des fichiers lorsque le composant est "unmount" (donc quand on change de page)
  useEffect(() => (
    () => dispatch(cleanFilesToUpload())
  ), [dispatch]);

  const handleFileChange = (target) => {
    if (target.files && target.files.length > 0) {
      dispatch(cleanFilesToUpload());
      setFileList(target.files);
    }
  };

  const handleUploadClick = () => {
    if (_.isEmpty(fileList)) {
      return;
    }
    _.forEach(fileList, (value) => {
      const fd = new FormData();
      fd.append('file', value);
      dispatch(uploadFileToCreateDocument(uploadParams, fd, value.name));
    });
  };

  return (
    <UploadFilesToCreateDocuments
      acceptExtensions={FILE_UPLOAD_FORMATS.docToUpload.fileExtensions}
      handleFileChange={handleFileChange}
      handleUploadClick={handleUploadClick}
      fileList={fileList}
      setFileList={setFileList}
      filesToUpload={filesToUpload}
    />
  );
};

UploadFilesToCreateDocumentsContainer.propTypes = {
  baseId: PropTypes.number.isRequired,
  baseMisePageId: PropTypes.number.isRequired,
};

export default UploadFilesToCreateDocumentsContainer;
